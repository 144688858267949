const cards = [
   {
      id: "img01",
      target: "",
      alt: "Banco Canela",
      title: "Banco Canela",
      description: "",
   },
   {
      id: "img02",
      target: "",
      alt: "Banco Baú Rústico Colorido",
      title: "Banco Baú Rústico Colorido",
      description: "",
   },
   {
      id: "img03",
      target: "",
      alt: "Banco Marquize-mt-natural-140x050",
      title: "Banco Marquize",
      description: "Medidas: 140x50cm. Mt.Natural",
   },
   {
      id: "img04",
      target: "",
      alt: "Banco Namoradeira Corao Cairo 2 lug 120cm-mt-natural",
      title: "Banco Namoradeira Corao Cairo 2 Lugares",
      description: "Comprimento: 120cm. Mt.Natural",
   },
   {
      id: "img05",
      target: "",
      alt: "Banco Sofá Design-mt-natural-110",
      title: "Banco Sofá Design",
      description: "Comprimento: 110cm. Mt.Natural",
   },
   {
      id: "img06",
      target: "",
      alt: "Jogo de Bancos Namoradeira Corao Cairo Natural",
      title: "Jogo de Bancos Namoradeira Corao Cairo Natural",
      description: "",
   },
   {
      id: "img07",
      target: "",
      alt: "Banco Clássico",
      title: "Banco Clássico",
      description: "",
   },
   {
      id: "img08",
      target: "",
      alt: "Banco Flow-verniz castanho",
      title: "Banco Flow",
      description: "Verniz Castanho",
   },
   {
      id: "img09",
      target: "",
      alt: "Banco Indiano sem estofado",
      title: "Banco Indiano sem estofado",
      description: "",
   },
   {
      id: "img10",
      target: "",
      alt: "Banco Lupe",
      title: "Banco Lupe",
      description: "",
   },
   {
      id: "img11",
      target: "",
      alt: "Banco Nino",
      title: "Banco Nino",
      description: "",
   },
   {
      id: "img12",
      target: "",
      alt: "Banco Viena",
      title: "Banco Viena",
      description: "",
   },
   {
      id: "img13",
      target: "",
      alt: "Banco Indiana 2",
      title: "Banco Indiana II",
      description: "",
   },
   {
      id: "img14",
      target: "",
      alt: "Conjunto Banco Primavera",
      title: "Conjunto Banco Primavera",
      description: "",
   },
   {
      id: "img15",
      target: "",
      alt: "Mesa e Banco Primavera",
      title: "Mesa e Banco Primavera",
      description: "",
   },
]

export { cards }